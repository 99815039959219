<template>
  <a-list item-layout="horizontal" :data-source="data" />
</template>

<script>
export default {
  data() {
    return {
      data: []
    }
  },
  methods: {}
}
</script>

<style scoped></style>
